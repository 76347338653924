div:focus {
  outline: none;
}

.person-icon-value.safari-only {
  transform: translate(17px, 24px);
}

.person-icon-value {
  transform: translate(17px, 9px);
}

.person-icon-value-ie {
  display: none;
}

@supports (-ms-ime-align: auto) {
  .person-icon-value {
    transform: translate(17px, 24px) !important;
  }
}

@media screen and (-ms-high-contrast: active),
screen and (-ms-high-contrast: none) {
  .person-icon-value {
    display: none !important;
  }

  .person-icon-value-ie {
    display: block !important;
  }
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.space-tooltip {
  margin-bottom: 7px;
}

input[disabled] {
  cursor: not-allowed;
}

.rmdBlueLine {
    padding-top: 4px !important;
}

@media(min-width: 620px){
  .strategies-percentage-status.error{
    width: 120% !important;
  }
}

.logo {
  background-color: transparent !important;
  border-radius: 0 !important;
}

@media(min-width: 961px) {
  .logo {
    padding-left: 0px !important;
  } 
}

@media(max-width: 960px){
  .logo {
    padding-left: 12px !important;
  }
}

.years-deferral-error {
    display: block;
    white-space: nowrap;
}

@media(max-width: 321px) {
    .years-deferral-error {
        white-space: normal;
    }
}